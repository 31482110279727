import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { LoadingService } from "./loading.service";
import { Router } from "@angular/router";
import { AlertService } from "./alert.service";
import { ToastrService } from "ngx-toastr";

@Injectable({
	providedIn: 'root'
})
export class XtrasService {

    constructor(
        private alert: AlertService,
        private load: LoadingService,
        private toast: ToastrService,
        private router: Router,
    ) {}

    filterEmptyParams(formData: { [key: string]: any }) {
        return Object.fromEntries(
            Object.entries(formData).filter(
                ([_, value]) => value !== null && value !== undefined && value !== ''
            )
        );
    }

    lengthItems(items: any) {
        if(Array.isArray(items)) {
            if(items.length >= 1) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    scrollToTarget(el: HTMLElement) { // example <div #algo> scrollToTarget(algo)
        el.scrollIntoView({behavior: 'smooth'});
    }

    checkFieldsSame(field1: string, field2: string): ValidatorFn {
        return (group: AbstractControl): { [key: string]: any } | null => {
            const _field1 = group.get(field1);
            const _field2 = group.get(field2);
            return _field1 && _field2 && _field1.value === _field2.value ? null : { notSame: true };
        };
    }

    getErrorApi(result: any, loading:boolean = false, redirectError: string = '/', errorDefault?:string) {
        if(loading) {
            this.load.dismiss();
        }
        if(result?.others?.redirect_url) {
            this.router.navigateByUrl(result?.others?.redirect_url);
            this.alertErrorApi(result, errorDefault);
            return;
        }
        if(result['redirect']) {
          this.router.navigateByUrl(redirectError);
        }
        this.alertErrorApi(result, errorDefault);
    }

    alertErrorApi(result: any, errorDefault?: string) {
        if(result['message']) {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: result['message'],
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.alert.alertCapYei({
                    type: 'error',
                    message: 'Error',
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['message']
                });
            }
        } else {
            if(result['errors']) {
                this.alert.alertCapYei({
                    type: 'error',
                    message: '¡Error!',
                    buttons: {
                        cancel: { show: false },
                        confirm: { show: true, text: 'Entendido' },
                    },
                    description: result['errors']
                });
            } else {
                this.toast.error((errorDefault ?? 'No se pudo obtener la información, intente nuevamente.'), '¡Error!',  {timeOut: 5000, closeButton: true, progressBar: true});
            }
        }
    }
}